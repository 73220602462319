<template>
  <section>
    <v-container
      class="fill-height"
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="8"
          md="4"
        >
          <v-card class="mx-auto">
            <v-toolbar
              color="primary"
              dark
              flat
            >
              <v-toolbar-title>Авторизация</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid"
              >
                <v-text-field
                  v-model="login"
                  label="Логин"
                  prepend-icon="mdi-account"
                  type="text"
                  :rules="loginRules"
                  required
                />

                <v-text-field
                  v-model="password"
                  label="Пароль"
                  prepend-icon="mdi-lock"
                  type="password"
                  :rules="passwordRules"
                  required
                />
              </v-form>
            </v-card-text>
            <v-card-actions>
              <div class="flex-grow-1" />
              <v-btn
                color="primary"
                :disabled="!valid"
                @click="doLogin"
              >
                Войти
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-snackbar
            v-model="snackbar"
            bottom
          >
            {{ error_message }}
          </v-snackbar>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
  export default {
    props: {
      source: String,
    },
    data: () => ({
      drawer: null,
      snackbar: false,
      valid: true,
      login: '',
      loginRules: [
        (v) => !!v || 'Укажите логин',
      ],
      password: '',
      passwordRules: [
        (v) => !!v || 'Укажите пароль',
      ],
      result: null,
      error_message: '',
    }),
    methods: {
      reset () {
        this.$refs.form.reset()
      },
      doLogin () {
        this.$http
          .post('/auth/token', {
            login: this.login,
            password: this.password,
          })
          .then((response) => {
            this.result = response.data
            if (this.result.jwt) {
              this.$store.state.isLogin = true
              this.$store.state.jwt_token = this.result.jwt
              this.$http.defaults.headers.common.Authorization = 'Bearer ' + this.result.jwt
              this.$router.push({ name: 'Пользователи' })
            } else {
              this.error_message = 'Что то пошло не так'
              this.snackbar = true
            }
          })
          .catch((err) => {
            if (err.response && err.response.status === 401) {
              this.error_message = 'Неверный логин или пароль'
            } else {
              this.error_message = 'Что то пошло не так'
            }
          })
      },
    },
  }
</script>
